<template>
  <footer>@2022 Fog Hashing Pte. Ltd</footer>
</template>
<script>
export default {}
</script>

<style scoped>
footer {
    margin-top: auto;
    padding: 10em;
  
  
    text-align: center;
}
</style>