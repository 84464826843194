<template>
  <div class="home">
    "Welcome to Home"
  </div>
</template>

<script>
export default {
  created () {
    if (window.sessionStorage.getItem('token')) {
      if (window.sessionStorage.getItem('is_staff')) {
        this.$router.push({name: 'ManagerHome'});
      } else {
        this.$router.push({name: 'User', params: { user_id: window.sessionStorage.getItem('user_id') }});
      }
    } else {
      this.$router.push({name: 'Login'});
    }
  }
}
</script>
