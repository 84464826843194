import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/changepassword/:user_id',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    props: true
  },
  {
    path: '/managerHome',
    name: 'ManagerHome',
    component: () => import('../views/ManagerHome.vue')
  },
  { 
      path: '/user/:user_id', 
      name: 'User',
      component: () => import('../views/User.vue'), 
      props: true 
  },
  { 
    path: '/addUser', 
    name: 'AddUser',
    component: () => import('../views/AddUser.vue'), 
    props: true 
  },
  { 
    path: '/user/:user_id/farm/:farm_id', 
    name: 'Farm',
    component: () => import('../views/Farm.vue'), 
    props: true
  },
  {
    path: '/user/:user_id/farm/:farm_id/tank/:tank_id',
    name: 'TankDetail',
    component: () => import('../views/TankDetail.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
