<template>
  <div class="q-pa-md" style="margin-bottom: 24px;background-color: rgb(235, 235, 235); box-shadow: rgb(220, 220, 220)">
    <div class="row justify-between items-center">
        <div class="col-10 row justify-start items-center">
        <q-img class="col-6" src="../assets/logo.png" style="max-width: 300px" v-on:click="$router.push({ name: 'Home'})"></q-img> 
        <div class="col-4" style="font-size: 15px; padding-left: 5px;">{{ $t("app.brand_title") }}</div>
        </div>     
        <q-btn color="gray" outline icon="person" class="order-last col-xs-1">
        <q-menu >
          <q-list style="width: 100px">
            <q-item>
             <q-select class="col-xs-1" style="width: 50px" filled v-model="locale" :options="$i18n.availableLocales" >
            </q-select>
            </q-item>
            <q-item clickable v-close-popup>
              <q-item-section @click="$router.push({name: 'Logout'})">{{ $t('login.logout') }}</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
        </q-btn>

    </div>
</div>
</template>
<script>
export default {
  data () {
    return {
      locale: window.sessionStorage.getItem('locale'),
    }
  },
  watch: {
    locale: function() {
      this.$i18n.locale = this.locale;
      window.sessionStorage.setItem('locale', this.locale);
    }
  }

}
</script>

