import passwordValidator from 'password-validator';


var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(32)                                   // Maximum length 100
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().not().spaces()                           // Should not have spaces

function check_password(password) {
    return schema.validate(password, { details: true }) 
}


export default {
  install: (app) => {
    app.mixin({
      methods: {
        $valid_password (password) {
          let result = check_password(password);
          for (let item of result) {
            let key = 'password_validate.' + item.validation;

            let tr = this.$t('password_validate.'+item.validation);
            if (tr && tr != key) {
              item.message = tr;
            }
          }
          return result;
        },
        async $fetch() {
          var mainArguments = Array.prototype.slice.call(arguments);
          
          const headers = {
            'Authorization': ' Token ' + window.sessionStorage.getItem('token')
          }
          if (mainArguments.length >= 2) {
            mainArguments[1].headers = headers;
          } else {
            mainArguments.push({headers: headers});
          }
          let response = await fetch.apply(undefined, mainArguments);
          if (response.status == 401 && window.sessionStorage.getItem('token') == null) {
            this.$router.push({name: 'Login'});
          } else {
            return response;
          }

        }
      }
    });
  },
};