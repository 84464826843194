const messages = {
    en: {
        login: {
            login: 'LOGIN',
            logout: 'LOGOUT',
            user_id: 'USER ID',
            username: 'USER NAME',
            remark: 'REMARK',
            password: 'PASSWORD',
            submit: 'LOGIN',
            relogin: 'Login Failed: Your username or password is incorrect!',
            forget_password: 'Forgot password?',
            contact_us: 'Please contact us.',
            change_admin_password: 'Change SysAdmin Password',
            change_password: 'Change Password',
            password_not_match: 'The two input passwords do not match',
            new_password: 'New Password',
            new_password_again: 'New Password Again',
        },
        form: {
            submit: 'SUBMIT'
        },
        alert: {
            title: 'ALERT',
        },
        error: {
            retry: 'Some error occurred, please retry'
        },
        app: {
            brand_title: 'Immersion Cooling for Crypto Mining',
            user_list: 'USERS LIST',
            edit: 'Edit',
            user: 'User',
            add_user: 'ADD USER',
            cancel: 'Cancel',
            delete: 'Delete',
            ok: 'OK',
            go_back: 'BACK',
            user_exist: 'username exists',
            farm: 'Farm',
            tower: 'Cooling Tower',
            tank: 'Tank',
            miner: 'Mining Machine',
            new_farm_name: 'New Farm Name',
          
            add_farm: 'NEW',
            add_tank: 'NEW',
            token: 'TOKEN',

            delete_farm: 'Delete Farm',

            delete_confirm_message: 'Delete this item will also delete all facilites binding to it and related history data. Do you really want to delete it?',
            delete_type_message: 'Please type "DELETE" below',
            
            temp_sw: 'Set Temp',
            circulation_state: 'Circulation State', 
            temp_sensors: 'Temp Sensors', 
            liquid_level: 'Liquid Level', 
            pump_speed: 'Pump Speed', 

            env_temp: "Environment temp",
            fan_freq: "Fan frequency",
            storage_water_level: "Storage water level",
            water_flow: "Water flow",
            water_temp: "Water temp",
            operating: 'Operating ',

            check_new: 'Check New',
            temp1: 'Temp1',
            temp2: 'Temp2',
            firmware_version: 'Firmware Version',
            version: 'Firmware Version',
            miner_switch_desc: '*Toggle Button to power on/off each mining machine',

            offline: 'OFFLINE',
            online: 'ONLINE',
        }
    },
    zh: {
        login: {
            login: '登陆',
            logout: '登出',
            user_id: '用户ID',
            username: '用户名',
            remark: '备注',
            password: '密码',
            submit: '登录',
            relogin: '账号/密码有误',
            forget_password: '忘记密码？',
            contact_us: '请联系我们。',
            change_admin_password: '修改系统管理员密码',
            change_password: '修改密码',
            password_not_match: '两次输入密码不一致',
            new_password: '输入新密码',
            new_password_again: '请再次输入新密码',
        },
        form: {
            submit: '提交'
        },
        alert: {
            title: '警告'
        },
        password_validate: {
            min: '最少8个字符',
            max: '最多32个字符',
            uppercase: '必须包含1个大写字母',
            lowercase: '必须包含1个小写字母',
            space: '不能有空格'
        },
        error: {
            retry: '出现异常，请重试'
        },
        app: {
            brand_title: '浸没式液冷技术提供商',
            user: '用户',
            user_list: '用户列表',
            edit: '编辑',
            add_user: '新增用户',
            cancel: '放弃',
            ok: '确定',
            delete: '删除',
            go_back: '返回',
            user_exist: '用户名已存在',
            farm: '数据中心',
     
            tower: '冷却塔',
            tank: '机架',
            miner: '矿机',
            new_farm_name: '矿场名称',
            add_farm: '新增',
            add_tank: '新增',
            token: '匹配码',

            delete_farm: '删除矿场',

            delete_confirm_message: '删除此项，会删除绑定到该项上的所有设备以及历史记录数据',
            delete_type_message: '确认删除请手动输入"DELETE"',

            env_temp: "环境温度",
            fan_freq: "风扇转速",
            storage_water_level: "水位",
            water_flow: "水流量",
            water_temp: "水温",

            check_new: '刷新',
            temp1: '温度1', 
            temp2: '温度2', 
            firmware_version: '固件版本',
            version: '固件版本',
            operating: '操作中',

            circulation_state: '循环状态',
            temp_sensors: '温度传感器', 
            liquid_level: '液面', 
            pump_speed: '水泵转速', 
            temp_sw: '设定温度',

            miner_switch_desc: '*使用以上按钮打开或关闭单台矿机的电源',

            offline: '离线',
            online: '在线',
        }
    }
}

export default messages
