import { createApp } from 'vue'
import App from './App.vue'

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

const app = createApp(App)
app.use(Quasar, quasarUserOptions)


import router from './router'
app.use(router)


import { createI18n } from 'vue-i18n'
import messages from './i18n'

let locale = window.sessionStorage.getItem('locale');
if (locale == null) {
    const locale = window.navigator.language.split('-')[0];
    window.sessionStorage.setItem('locale', locale);
}
app.use(createI18n({locale: locale, fallbackLocale: 'en', messages: messages}))


import OnePlugin from './plugins/one_plugin.js'
app.use(OnePlugin)

app.mount('#app')
