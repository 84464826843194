<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">

  <Header></Header>
  <router-view />
  
  <Footer></Footer>

</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {Header, Footer},
  created () {
    document.title = "foghashing";
    document.getElementsByTagName('body')[0].style.backgroundColor = 'rgb(245, 245, 245)';
  }
}
</script>
